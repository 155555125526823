import React from 'react';
import * as Styled from './styled';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  compressed?: boolean;
  snug?: boolean;
  shadow?: boolean;
  stretch?: boolean;
  error?: boolean;
  gap?: number;
};

const Card: React.FC<CardProps> = ({
  children,
  shadow,
  compressed,
  snug,
  error,
  gap = 25,
  stretch = false,
  ...rest
}) => {
  return (
    <Styled.Card
      $compressed={compressed}
      $shadow={shadow}
      $error={error}
      $stretch={stretch}
      $gap={gap}
      $snug={snug}
      {...rest}
    >
      {children}
    </Styled.Card>
  );
};

const InfoCard: React.FC<CardProps> = ({ children, compressed, snug, error, gap = 25, stretch = false, ...rest }) => {
  return (
    <Styled.InfoCard $compressed={compressed} $error={error} $stretch={stretch} $gap={gap} $snug={snug} {...rest}>
      {children}
    </Styled.InfoCard>
  );
};

const AlternateCard: React.FC<CardProps> = ({
  children,
  compressed,
  snug,
  error,
  gap = 25,
  stretch = false,
  ...rest
}) => {
  return (
    <Styled.AlternateCard $compressed={compressed} $error={error} $stretch={stretch} $gap={gap} $snug={snug} {...rest}>
      {children}
    </Styled.AlternateCard>
  );
};

const WarningCard: React.FC<CardProps> = ({
  children,
  compressed,
  snug,
  error,
  gap = 25,
  stretch = false,
  ...rest
}) => {
  return (
    <Styled.WarningCard $compressed={compressed} $error={error} $stretch={stretch} $gap={gap} $snug={snug} {...rest}>
      {children}
    </Styled.WarningCard>
  );
};

const ErrorCard: React.FC<CardProps> = ({
  children,
  shadow,
  compressed,
  snug,
  error,
  gap = 25,
  stretch = false,
  ...rest
}) => {
  return (
    <Styled.ErrorCard
      $compressed={compressed}
      $shadow={shadow}
      $error={error}
      $stretch={stretch}
      $gap={gap}
      $snug={snug}
      {...rest}
    >
      {children}
    </Styled.ErrorCard>
  );
};

const BorderFadeCard: React.FC<CardProps & { borderWidth?: number; background?: string }> = ({
  children,
  compressed,
  snug,
  error,
  stretch = false,
  borderWidth = 1,
  shadow = false,
  background,
  ...rest
}) => {
  return (
    <Styled.BorderFadeCard $borderWidth={borderWidth} $shadow={shadow} $background={background}>
      <Styled.Card $compressed={compressed} $error={error} $stretch={stretch} $snug={snug} {...rest}>
        {children}
      </Styled.Card>
    </Styled.BorderFadeCard>
  );
};

export default Object.assign(Card, {
  Alternate: AlternateCard,
  BorderFade: BorderFadeCard,
  Info: InfoCard,
  Warning: WarningCard,
  Error: ErrorCard,
});
