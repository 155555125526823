'use client';
import * as Styled from './styled';

import { search } from '@/lib/searchApi';
import AsyncSelect from 'react-select/async';
import { SingleValue as TSingleValue } from 'react-select';
import * as Icons from '@/ui/Icon';

import { ISearchResponseWithTermAndType, SingleValue, SearchOption, Control, GroupHeading } from './components';

export { default as CollaboratorSearch } from './CollaboratorSearch';
export { default as RepositorySearch } from './RepositorySearch';

export const MainSearch: React.FC<{
  query?: string;
  selection?: any;
  placeholder?: string;
  helpText?: string;
  instanceId: string;
  error?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}> = ({
  query,
  selection,
  placeholder = 'Enter Something to Search for',
  helpText = 'Enter Something to Search for',
  instanceId,
  autoFocus = false,
  disabled = false,
  error = false,
  ...props
}) => {
  const selectionChanged = (option?: TSingleValue<ISearchResponseWithTermAndType>) => {
    const win: Window = window;
    win.location = `/${option?.namespace}/${option?.name}`;
  };

  const optionLoader = async (term: string) => {
    const { repositories, organizations, users } = await search(term);

    // Mapping each repository to include searchTerm in its object
    const repositoriesWithOptions =
      repositories?.map((repository: ISearchResponseWithTermAndType) => ({
        searchTerm: term,
        resultType: 'repository',
        ...repository,
      })) || [];

    const organizationsWithOptions =
      organizations?.map((organization: any) => ({
        searchTerm: term,
        resultType: 'organization',
        ...organization,
      })) || [];

    const usersWithOptions =
      users?.map((user: any) => ({
        searchTerm: term,
        resultType: 'user',
        ...user,
      })) || [];

    const options = [
      {
        label: 'Repositories',
        options: repositoriesWithOptions,
      },
      {
        label: 'Organizations',
        options: organizationsWithOptions,
      },
      {
        label: 'Users',
        options: usersWithOptions,
      },
    ];

    return options;
  };

  return (
    <Styled.Search>
      <AsyncSelect
        styles={{
          menuList: (base) => ({
            ...base,
            maxHeight: '80vh',
          }),
        }}
        maxMenuHeight={1000}
        isDisabled={disabled}
        autoFocus={autoFocus}
        escapeClearsValue
        instanceId={instanceId}
        placeholder={placeholder}
        className="multisearch-select-container"
        classNamePrefix="multisearch-select"
        components={{
          Option: SearchOption,
          SingleValue,
          Control: (controlProps) => <Control {...controlProps} prefix={<Icons.Search />} />,
          GroupHeading,
        }}
        loadOptions={(q) => optionLoader(q)}
        onChange={(v) => selectionChanged(v as TSingleValue<ISearchResponseWithTermAndType>)}
        noOptionsMessage={(t) => (t.inputValue ? `No match for "${t.inputValue}" found` : helpText)}
        {...props}
      />
    </Styled.Search>
  );
};
