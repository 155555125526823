'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Search = styled.div<{}>`
  .multisearch-select__control {
    border-color: ${colors.secondary.lightGray3};
  }
  .multisearch-select__indicator-separator {
    display: none;
  }
`;

export const Label = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  width: auto;
  border-radius: 5px;
  gap: 10px;
`;

export const OptionValue = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .RepositoryDataTypesWithSize {
    flex-direction: row-reverse;
  }

  .identity {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .result-highlight {
    font-weight: bold;
  }

  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
