import { useState } from 'react';
import { createContainer } from 'unstated-next';
import Cookies from 'js-cookie';
import tracking from '@/lib/tracking';

import { SESSION_TIMEOUT } from '@/config/appSettings';

import { IUser } from '@/types';
import { IResponseError } from '@/lib/apiBase';
import * as API from '@/lib/usersApi';
import * as Sentry from '@sentry/nextjs';

export const persistCredentials = (user: IUser) => {
  Cookies.set('oxen_user_id', user.id, { expires: SESSION_TIMEOUT });
  Cookies.set('oxen_username', user.username, { expires: SESSION_TIMEOUT });
  if (user.token) {
    Cookies.set('oxen_user_token', user.token, { expires: SESSION_TIMEOUT });
  }
};

const useIdentity = (serverUser?: IUser) => {
  const [user, setUser] = useState<IUser | undefined>(serverUser);
  const [status, setStatus] = useState<'success' | 'error' | 'pending' | 'loading'>(
    !!serverUser?.id ? 'success' : 'pending',
  );
  const [error, setError] = useState<IResponseError | undefined>();

  if (user?.username) {
    Sentry.setUser({ username: user?.username });
  }

  // Hack to persist username in cookies for currently logged in user
  if (user?.username && !Cookies.get('oxen_username')) {
    Cookies.set('oxen_username', user.username, { expires: SESSION_TIMEOUT });
  }

  const clearSession = (): void => {
    Cookies.remove('oxen_user_token');
    Cookies.remove('oxen_username');
    setStatus('pending');
    setUser(undefined);
    setError(undefined);
  };

  const isUserComplete = (): boolean => !!user && user.status === 'complete';

  const clearError = (): void => {
    setError(undefined);
  };

  const authorizeSuccess = (response: API.IUserResponse): void => {
    setError(undefined);
    setUser(response.user);
  };

  const authorizeFailed = (response: API.IUserResponse): void => {
    Cookies.remove('oxen_user_token');
    setUser(undefined);
    setError(response.error as IResponseError);
  };

  const loginSuccess = async (response: API.IUserResponse): Promise<void> => {
    setError(undefined);
    const { user } = response;
    if (user) {
      tracking.identify(user);
      tracking.capture('user_logged_in');
      persistCredentials(user);
      delete user.token;
      setUser(user);
    }
  };

  const loginFailed = (identifier: string, response: API.IUserResponse): void => {
    setError(response.error as IResponseError);
    tracking.capture('user_login_failed', { identifier: identifier, error: response.error });
    console.log('loginFailed', response.error);
    setUser(undefined);
    Cookies.remove('oxen_user_token');
  };

  const registerSuccess = async (response: API.IUserResponse): Promise<void> => {
    setError(undefined);
    const { user } = response;
    if (user) {
      tracking.identify(user);
      tracking.capture('user_registered');
      persistCredentials(user);
      delete user.token;
      setUser(user);
    }
  };

  const registerFailed = (response: API.IUserResponse): void => {
    setError(response.error as IResponseError);
    tracking.capture('user_registration_failed', response.error);
    setUser(undefined);
    Cookies.remove('oxen_user_token');
  };

  const completeAccountSuccess = (response: API.IUserResponse): void => {
    setError(undefined);
    const { user } = response;
    if (user) {
      tracking.identify(user);
      tracking.capture('user_registered');
      persistCredentials(user);
      delete user.token;
      setUser(user);
    }
  };

  const completeAccountFailed = (response: API.IUserResponse): void => {
    setError(response.error as IResponseError);
    tracking.capture('user_registration_failed', response.error);
    setUser(undefined);
    Cookies.remove('oxen_user_token');
  };

  const updateFailed = (response: API.IUserResponse): void => {
    setError(response.error as IResponseError);
  };

  const updateSuccess = (response: API.IUserResponse): void => {
    setError(undefined);
    const { user } = response;
    if (user) {
      setUser(user);
    }
  };

  const register = async ({
    email,
    name,
    username,
    password,
    referralCode,
    onSuccess,
  }: API.IRegisterParams & { onSuccess?: Function }): Promise<string> => {
    setStatus('loading');
    const registerResponse = await API.register({ email, name, username, password, referralCode });
    setStatus(registerResponse.status);

    if (registerResponse.status === 'success') {
      await registerSuccess(registerResponse);
      if (onSuccess) {
        onSuccess();
      } else {
        const win: Window = window;
        win.location = '/';
      }
    } else {
      registerFailed(registerResponse);
    }
    return await registerResponse?.status;
  };

  const completeAccount = async ({
    email,
    name,
    username,
    referralCode,
    onSuccess,
    password,
  }: API.ICompleteAccountParams & { onSuccess?: Function }): Promise<string> => {
    setStatus('loading');
    const completeAccountResponse = await API.completeAccount({ email, name, username, password, referralCode });
    setStatus(completeAccountResponse.status);

    if (completeAccountResponse.status === 'success') {
      completeAccountSuccess(completeAccountResponse);
      if (onSuccess) {
        onSuccess();
      } else {
        const win: Window = window;
        win.location = '/';
      }
    } else {
      completeAccountFailed(completeAccountResponse);
    }
    return await completeAccountResponse?.status;
  };

  const update = async ({ email, name, password, image }: API.IUpdateParams): Promise<string> => {
    setStatus('loading');
    if (!user) {
      return 'error';
    }
    const updateResponse = await API.updateUser({
      userId: user?.id,
      email,
      name,
      username: user?.username,
      image,
      password,
    });
    setStatus(updateResponse.status);
    if (updateResponse.status === 'success') {
      updateSuccess(updateResponse);
    } else {
      updateFailed(updateResponse);
    }
    return await updateResponse?.status;
  };

  const login = async ({
    identifier,
    password,
    onSuccess,
  }: API.ILoginParams & { onSuccess?: Function }): Promise<string> => {
    setStatus('loading');
    const response = await API.login({ identifier, password });
    setStatus(response.status);
    if (response.status === 'success') {
      await loginSuccess(response);
      if (onSuccess) {
        onSuccess();
      } else {
        const win: Window = window;
        win.location = '/';
      }
    } else {
      loginFailed(identifier, response);
    }
    console.log(response);
    return await response?.status;
  };

  const logout = () => {
    clearSession();
    tracking.reset();
    const win: Window = window;
    win.location = '/';
  };

  const authorize = async (): Promise<void> => {
    const token = Cookies.get('oxen_user_token');
    if (token) {
      const authResponse = await API.authorize(token);
      const status = authResponse?.status;

      if (status) {
        setStatus(status);
        if (status === 'success') {
          authorizeSuccess(authResponse);
        } else {
          authorizeFailed(authResponse);
        }
      }
    }
  };

  return {
    user,
    register,
    isUserComplete,
    completeAccount,
    login,
    logout,
    authorize,
    update,
    status,
    error,
    clearError,
    clearSession,
  };
};

export const Identity = createContainer(useIdentity);

// export default useIdentity;
