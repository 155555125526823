import React from 'react';
import * as Styled from './styled';

const Box: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { children: React.ReactNode; debug?: boolean; padding?: number }
> = ({ children, debug, padding, ...rest }) => {
  return (
    <Styled.Box $padding={padding} $debug={debug} {...rest}>
      {children}
    </Styled.Box>
  );
};

export default Box;
