import { colors } from '@/styles';
import * as format from '@/lib/format';

import * as Styled from './styled';
import { IRepositoryFolder, IRepository, IRepositoryDataType, TEntryDataType } from '@/types';
import * as Icon from '@/ui/Icon';
import Tag from '@/ui/Tag';
import { Row, Stack } from '@/ui/primatives';
import pluralize from 'pluralize';

const DataTypeIcon: React.FC<{ dataType: TEntryDataType }> = ({ dataType }) => {
  {
    switch (dataType) {
      case 'image':
        return <Icon.Image />;
      case 'video':
        return <Icon.Video />;
      case 'text':
        return <Icon.Text />;
      case 'audio':
        return <Icon.Audio />;
      case 'tabular':
        return <Icon.Table />;
      case 'binary':
        return <Icon.Binary />;
      case 'directory':
        return <Icon.FolderOpen />;
      default:
        return <Icon.QuestionCircle />;
    }
  }
};

const RepositoryDataTypeTag: React.FC<{ dataType: IRepositoryDataType }> = ({ dataType }) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  const backgroundColor = colors.dataType[dataType.data_type as TEntryDataType];
  const humanCount = formatter.format(dataType.count);
  const longText = pluralize(`${dataType.data_type} file`, dataType.count, false);
  return (
    <Tag
      key={dataType.data_type}
      small
      variant="default"
      style={{ background: backgroundColor }}
      title={`${humanCount} ${longText}`}
    >
      <DataTypeIcon dataType={dataType.data_type} />
      <span>{humanCount}</span>
    </Tag>
  );
};

const RepositoryDataTypes: React.FC<{ repository: IRepository; folder?: IRepositoryFolder }> = ({
  repository,
  folder,
}) => {
  const folderDataTypes = folder?.dir?.metadata?.dir?.data_types;
  const activeDataTypes = ((folderDataTypes as IRepositoryDataType[]) || repository.data_types)
    ?.filter((dataType) => dataType.count > 0)
    .filter((dataType) => dataType.data_type !== 'directory');
  return (
    <>
      <Row gap={10} style={{ flexWrap: 'wrap' }}>
        {activeDataTypes?.map((dataType) => (
          <RepositoryDataTypeTag key={dataType.data_type} dataType={dataType as IRepositoryDataType} />
        ))}
      </Row>
    </>
  );
};

export const RepositoryDataTypesChart: React.FC<{ repository: IRepository; folder?: IRepositoryFolder }> = ({
  repository,
  folder,
}) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  const folderDataTypes = folder?.dir?.metadata?.dir?.data_types;
  const activeDataTypes = ((folderDataTypes as IRepositoryDataType[]) || repository.data_types)
    ?.filter((dataType) => dataType.count > 0)
    .filter((dataType) => dataType.data_type !== 'directory');

  const totalFileCount = activeDataTypes?.reduce((acc, dataType) => acc + dataType.count, 0);

  const activeDataTypesWithPercent = activeDataTypes?.map((dataType) => ({
    ...dataType,
    percent: (dataType.count / totalFileCount) * 100,
  }));

  const renderPercentage = (percent: number): string => {
    if (percent < 1) {
      return '< 1%';
    }
    if (percent > 99) {
      return '> 99%';
    }
    return `${percent.toFixed(1)}%`;
  };
  return (
    <Stack gap={20}>
      <Styled.RepositoryDataTypesChart>
        {activeDataTypesWithPercent
          ?.sort((a, b) => b.percent - a.percent)
          .filter((dataType) => dataType.percent >= 1) // filter out data types with less than 1% to prevent rendering issues
          .map((dataType) => (
            <div
              key={dataType.data_type}
              style={{
                width: `${dataType.percent}%`,
                background: colors.data[dataType.data_type as TEntryDataType].dark,
              }}
            />
          ))}
      </Styled.RepositoryDataTypesChart>
      <Styled.RepositoryDataTypeIndicators>
        {activeDataTypesWithPercent?.map((dataType) => (
          <Styled.RepositoryDataTypeIndicator key={`indicator_${dataType.data_type}`}>
            <div className="bullet" style={{ background: colors.data[dataType.data_type as TEntryDataType].dark }} />
            <div className="description">
              <span>{formatter.format(dataType.count)} </span>
              <b>
                <span className="data-type">{dataType.data_type}</span> files
              </b>{' '}
              <span>{renderPercentage(dataType.percent)}</span>
            </div>
          </Styled.RepositoryDataTypeIndicator>
        ))}
      </Styled.RepositoryDataTypeIndicators>
    </Stack>
  );
};

export const RepositoryDataTypesWithSize: React.FC<{
  repository: IRepository;
  folder?: IRepositoryFolder;
  sizeInBytes?: number;
}> = ({ repository, folder, sizeInBytes }) => {
  return (
    <Row gap={10} alignItems="center" className="RepositoryDataTypesWithSize">
      {!!sizeInBytes ? (
        <Tag small variant="white" border>
          {format.humanFileSize(sizeInBytes)}
        </Tag>
      ) : (
        <Tag small variant="white" border>
          Empty
        </Tag>
      )}
      <RepositoryDataTypes repository={repository as IRepository} folder={folder} />
    </Row>
  );
};

export default RepositoryDataTypes;
