import { apiClient, fetchWrapper, getRequestHeaders, IBaseResponse, IResponseError } from './apiBase';
import { AxiosResponse } from 'axios';
import { IUser } from '@/types';
import { BASE_API_URL } from '@/config/environment';

type TMethod =
  | 'login'
  | 'auth'
  | 'register'
  | 'get'
  | 'forgot_password'
  | 'reset_password'
  | 'send_magic_log_in'
  | 'magic_log_in'
  | 'complete_account';

export interface IUserResponse extends IBaseResponse {
  method: TMethod;
  user?: IUser;
  error?: IResponseError;
}

interface IProviderUsernameError extends IResponseError {
  provider_names?: string[];
}

export interface IProviderUsernameResponse extends IBaseResponse {
  username?: string;
  provider_name?: string;
  error?: IProviderUsernameError;
}

export interface IRegisterParams {
  email: string;
  name?: string;
  username?: string;
  password?: string;
  referralCode?: string;
}

export interface ICompleteAccountParams {
  name: string;
  username: string;
  referralCode?: string;
  email: string;
  password?: string;
  token?: string;
}

export interface ILoginParams {
  identifier: string;
  password: string;
}

export interface IGoogleLoginParams {
  code: string;
}

export interface IMagicLinkLoginParams {
  token: string;
}

export interface IUpdateParams {
  userId?: string;
  email?: string;
  name?: string;
  username?: string;
  password?: string;
  image?: File;
  token?: string;
}

const requestErrorHandler = (res: AxiosResponse, method: TMethod): IUserResponse => {
  return Object.assign(res.data, { method, isLoading: false });
};

const responseHandler = (res: AxiosResponse, method: TMethod): IUserResponse => {
  return Object.assign(res.data, { method, isLoading: false });
};

export const authorize = async (token: string | undefined): Promise<IUserResponse | undefined> => {
  if (!token) {
    return;
  }
  const requestHeaders = getRequestHeaders(token);
  try {
    const response = await apiClient.get('/authorize', { headers: requestHeaders });
    return responseHandler(response, 'auth');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'auth');
  }
};

export const login = async ({ identifier, password }: ILoginParams): Promise<IUserResponse> => {
  try {
    const response = await apiClient.post('/login', { identifier, password }, { withCredentials: true });
    return responseHandler(response, 'login');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'login');
  }
};

// TODO: should this be removed?
export const getUser = async (username: string, token: string): Promise<IUserResponse> => {
  const requestHeaders = getRequestHeaders(token);
  try {
    const response = await apiClient.get(`/users/${username}`, { headers: requestHeaders });
    return responseHandler(response, 'get');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'login');
  }
};

export const forgotPassword = async (email: string): Promise<IUserResponse> => {
  try {
    const response = await apiClient.post(`/forgot_password`, { email });
    return responseHandler(response, 'forgot_password');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'forgot_password');
  }
};

export const resetPassword = async (password: string, resetToken: string): Promise<IUserResponse> => {
  try {
    const response = await apiClient.post(`/reset_password`, { password, reset_token: resetToken });
    return responseHandler(response, 'reset_password');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'reset_password');
  }
};

export const sendMagicLink = async (identifier: string, redirectPath: string): Promise<IUserResponse> => {
  try {
    const response = await apiClient.post(`/send_magic_log_in`, { identifier, redirectPath });
    return responseHandler(response, 'send_magic_log_in');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'send_magic_log_in');
  }
};

export const logInWithMagicLink = async (token: string | null): Promise<IUserResponse | null> => {
  try {
    if (!token) {
      return null;
    }
    const response = await apiClient.post(`/magic_log_in`, { token });
    return responseHandler(response, 'magic_log_in');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'magic_log_in');
  }
};

export const register = async ({
  email,
  name,
  password,
  username,
  referralCode,
}: IRegisterParams): Promise<IUserResponse> => {
  const postData: Object = {
    user: {
      email,
      name,
      password,
      username,
      referred_by: referralCode,
    },
  };
  try {
    const response = await apiClient.post('/register', postData);

    return responseHandler(response, 'register');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'register');
  }
};

export const completeAccount = async ({
  email,
  name,
  username,
  referralCode,
  password,
  token,
}: ICompleteAccountParams): Promise<IUserResponse> => {
  const postData: Object = {
    user: {
      email,
      name,
      username,
      referralCode,
      password,
      referred_by: referralCode,
    },
  };
  try {
    const requestHeaders = getRequestHeaders(token);

    const response = await apiClient.post('/complete_account', postData, { headers: requestHeaders });

    return responseHandler(response, 'complete_account');
  } catch (error: any) {
    return requestErrorHandler(error.response, 'complete_account');
  }
};

export const updateUser = async ({
  userId,
  username,
  name,
  email,
  image,
  token,
}: IUpdateParams): Promise<IUserResponse> => {
  const resourceUrl = `/users/${username}`;
  const requestHeaders = getRequestHeaders(token);
  requestHeaders['Content-Type'] = 'multipart/form-data';

  const formData = new FormData();
  // if (userId) {
  //   formData.append('id', userId);
  // }
  if (name) {
    formData.append('name', name);
  }
  if (email) {
    formData.append('email', email);
  }
  if (image) {
    formData.append('image', image);
  }
  // const postData: Object = {
  //   name: name,
  //   email: email,
  //   image: image,
  // };

  try {
    const res = await apiClient.put(resourceUrl, formData, { headers: requestHeaders });

    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export async function fetchUsernameFromOauthProvider(email: string): Promise<IProviderUsernameResponse> {
  try {
    const response = await apiClient.post('/oauth/username', { email });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function fetchUser(token?: string): Promise<IUserResponse> {
  const targetUrl = `${BASE_API_URL}/authorize`;

  return await fetchWrapper({
    resourceName: 'user',
    targetUrl,
    authToken: token,
    resourceContext: { hasToken: !!token },
  });
}

export const fetchCurrentUser = async (token?: string) => {
  if (!token) {
    // No token, no user - return currentUser undefined
    return { currentUser: undefined, error: undefined };
  }
  const { user, error } = await fetchUser(token);
  return { currentUser: user, error };
};
