'use client';
import * as Styled from './styled';

import { IRepository } from '@/types';
import { collaboratorSearch } from '@/lib/searchApi';
import AsyncSelect from 'react-select/async';
import Highlight from '@/ui/Highlight';
import { components, OptionProps, SingleValueProps, InputProps, SingleValue as TSingleValue } from 'react-select';

import { ISearchResponseWithTermAndType } from './components';

const SingleValueCollaborator = ({ data, ...otherProps }: SingleValueProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.SingleValue data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <span style={{ fontWeight: '550', background: 'white' }}>{data.namespace}</span>
        </div>
      </Styled.OptionValue>
    </components.SingleValue>
  );
};

const SearchOptionCollaborator = ({ data, ...otherProps }: OptionProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.Option data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
          </span>
        </div>
      </Styled.OptionValue>
    </components.Option>
  );
};

const Input = (props: InputProps<ISearchResponseWithTermAndType>) => <components.Input {...props} isHidden={false} />;

const CollaboratorSearch: React.FC<{
  repository: IRepository;
  query?: string;
  label?: string;
  setSearchValue: (value: string) => void;
  searchValue: string;
  selection?: any;
  placeholder?: string;
  helpText?: string;
  instanceId: string;
  error?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}> = ({
  repository,
  query,
  setSearchValue,
  searchValue,
  label,
  selection,
  placeholder = 'Search by username or email',
  helpText = 'Search by username or email',
  instanceId,
  autoFocus = false,
  disabled = false,
  error = false,
  ...props
}) => {
  const selectionChanged = (option?: TSingleValue<ISearchResponseWithTermAndType>) => {
    setSearchValue(option ? option.namespace : '');
  };

  const optionLoader = async (term: string) => {
    const { users } = await collaboratorSearch(term, repository);

    const usersWithOptions =
      users?.map((user: any) => ({
        searchTerm: term,
        resultType: 'user',
        ...user,
      })) || [];

    const options = [
      {
        options: usersWithOptions,
      },
    ];

    return options;
  };

  const onInputChange = (inputValue: string, actionMeta: { action: string }) => {
    if (actionMeta.action === 'input-change') {
      setSearchValue(inputValue);
    }
  };

  return (
    <>
      <Styled.Search>
        <Styled.Label>
          {label}
          <AsyncSelect
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '80vh',
              }),
              control: (base) => ({
                ...base,
                minWidth: '400px',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isFocused ? '#DEEBFF' : state.isSelected ? 'white' : base.backgroundColor,
                color: 'black',
                height: '100%',
              }),
            }}
            isClearable={true}
            inputValue={searchValue}
            onInputChange={onInputChange}
            controlShouldRenderValue={false}
            maxMenuHeight={1000}
            isDisabled={disabled}
            autoFocus={autoFocus}
            escapeClearsValue
            instanceId={instanceId}
            placeholder={placeholder}
            className="multisearch-select-container"
            classNamePrefix="multisearch-select"
            components={{
              DropdownIndicator: null,
              Input,
              Option: SearchOptionCollaborator,
              SingleValue: SingleValueCollaborator,
            }}
            loadOptions={(q) => optionLoader(q)}
            noOptionsMessage={({ inputValue }) => {
              return inputValue
                ? `
              The person you want to add doesn't have an Oxen account? Don't worry, use their email and we'll send them an
              invitation.
            `
                : helpText;
            }}
            onChange={(v) => selectionChanged(v as TSingleValue<ISearchResponseWithTermAndType>)}
            {...props}
          />
        </Styled.Label>
      </Styled.Search>
    </>
  );
};

export default CollaboratorSearch;
