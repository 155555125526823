'use client';
import React, { useRef } from 'react';
import * as Styled from './styled';

import { search } from '@/lib/searchApi';
import AsyncSelect from 'react-select/async';
import { SingleValue as TSingleValue, SelectInstance } from 'react-select';
import * as Icons from '@/ui/Icon';

export { default as CollaboratorSearch } from './CollaboratorSearch';

import { ISearchResponseWithTermAndType, SingleValue, SearchOption, Control, GroupHeading } from './components';

export const RepositorySearch: React.FC<{
  query?: string;
  selection?: any;
  placeholder?: string;
  helpText?: string;
  instanceId: string;
  error?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange: (value: { namespace: string; name: string; type?: string; clearSelectedValue?: () => void }) => void;
}> = ({
  query,
  selection,
  placeholder = 'Search for repositories',
  helpText = 'Search for repositories',
  instanceId,
  autoFocus = false,
  disabled = false,
  error = false,
  onChange,
  ...props
}) => {
  type DefaultOption = {
    readonly value: string;
    readonly label: string;
    readonly resultType: string;
    readonly namespace: string;
    readonly name: string;
  };

  const selectRef = useRef<SelectInstance<ISearchResponseWithTermAndType> | null>(null);

  const selectionChanged = (option?: TSingleValue<ISearchResponseWithTermAndType>) => {
    if (option) {
      onChange({
        namespace: option.namespace,
        name: option.name,
        type: option.resultType,
        clearSelectedValue: doClearValue,
      });
    }
  };

  const optionLoader = async (term: string) => {
    const { repositories } = await search(term);

    // Mapping each repository to include searchTerm in its object
    const repositoriesWithOptions =
      repositories?.map((repository: ISearchResponseWithTermAndType) => ({
        searchTerm: term,
        resultType: 'repository',
        ...repository,
      })) || [];

    const options = [
      {
        label: 'Repositories',
        options: repositoriesWithOptions,
      },
    ];

    return options;
  };

  const doClearValue = () => {
    selectRef.current?.clearValue();
  };

  return (
    <Styled.Search>
      <AsyncSelect
        ref={selectRef as any}
        styles={{
          menuList: (base) => ({
            ...base,
            maxHeight: '80vh',
          }),
        }}
        maxMenuHeight={1000}
        isDisabled={disabled}
        autoFocus={autoFocus}
        escapeClearsValue
        instanceId={instanceId}
        placeholder={placeholder}
        className="multisearch-select-container"
        classNamePrefix="multisearch-select"
        isClearable
        components={{
          Option: SearchOption,
          SingleValue,
          Control: (controlProps) => <Control {...controlProps} prefix={<Icons.Search />} />,
          GroupHeading,
        }}
        loadOptions={(q) => optionLoader(q)}
        onChange={(v) => selectionChanged(v as TSingleValue<ISearchResponseWithTermAndType>)}
        noOptionsMessage={(t) => (t.inputValue ? `No match for "${t.inputValue}" found` : helpText)}
        {...props}
      />
    </Styled.Search>
  );
};

export default RepositorySearch;
