import { IUser } from '@/types';
import posthog from 'posthog-js';

const reset = (reset_device_id?: boolean) => {
  posthog.reset(reset_device_id);
};

const capture = (event_name: string, properties?: any, options?: any) => {
  posthog.capture(event_name, properties, options);
};

// const identify = (distinct_id: string, properties?: any) => {
//   posthog.identify(distinct_id, properties);
// };

const identify = (user: IUser) => {
  posthog.identify(user.id, {
    email: user.email,
    name: user.name,
    username: user.username,
    plan: user.plan?.name,
    role: user.role,
  });
};

const tracking = { reset, capture, identify };

export default tracking;
