'use client';
import React from 'react';

import BoringAvatar from 'boring-avatars';
import * as Styled from './styled';
import { IOrganization, IUser, IEntityUser } from '@/types';

interface AvatarProps {
  theme?: any;
  name?: string;
  email?: string | null;
  size?: number;
}

const AVATAR_COLORS = ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'];
// const AVATAR_COLORS = ['#f87a7a', '#f9947d', '#f57ab1', '#e07de8', '#dbb2ed'];

const Avatar: React.FC<AvatarProps> = ({ email, name, size = 50, ...props }) => {
  return (
    <Styled.Avatar $size={size} {...props}>
      <BoringAvatar size={size} name={name || email || ''} variant="beam" colors={AVATAR_COLORS} />
    </Styled.Avatar>
  );
};

export const OrganizationAvatar: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { organization: IOrganization; size?: number }
> = ({ organization, size = 100, ...rest }) => {
  return (
    organization?.image && (
      <Styled.OrganizationAvatar $size={size} {...rest}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={organization.image} alt={organization.display_name || organization.name} />
      </Styled.OrganizationAvatar>
    )
  );
};

export const UserAvatar: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { user: IUser | IEntityUser; size?: number }
> = ({ user, size = 100, ...rest }) => {
  return user?.image ? (
    <Styled.UserAvatar $size={size} {...rest}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img key={user.image} src={user.image} alt={user.name || user.username} />
    </Styled.UserAvatar>
  ) : (
    <Avatar name={user.name} email={user.email} size={size} {...rest} />
  );
};

export default Avatar;
