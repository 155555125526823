'use client';

import styled from 'styled-components';
import { colors } from '@/styles';
import { TagVariant } from './index';

const tagColor = ($variant?: TagVariant) => {
  if ($variant == 'white') {
    return colors.semantic.white;
  }
  if ($variant == 'info') {
    return colors.semantic.information;
  }
  if ($variant == 'success') {
    return colors.semantic.positive;
  }
  if ($variant == 'error') {
    return colors.semantic.negative;
  }
  if ($variant == 'warning') {
    return colors.semantic.notice;
  }
  if ($variant == 'subtle') {
    return { light: colors.secondary.lightGray1, dark: 'inherit' };
  }
  if ($variant == 'primary') {
    return { light: colors.data.tabular.light, dark: colors.data.tabular.dark };
  }
  if ($variant == 'transparent') {
    return { light: 'inherit', dark: 'inherit' };
  }
  return colors.semantic.default;
};

export const Tag = styled.span<{ $variant?: TagVariant; $withBorder?: boolean; $pill?: boolean; $small?: boolean }>`
  padding: ${(props) => (props.$pill ? '2px 6px' : props.$small ? '4px 5px' : '5px 10px')};
  border-radius: ${(props) => (props.$pill ? '10px' : props.$small ? '3px' : '5px')};
  border: none;
  font-size: ${(props) => (props.$small ? '12px' : '14px')};
  background-color: ${(props) => tagColor(props.$variant).light};
  color: ${(props) => tagColor(props.$variant).dark};
  border: 1px solid ${(props) => (props.$withBorder ? tagColor(props.$variant).dark : 'transparent')};
  display: inline-flex;
  align-items: center;
  gap: ${(props) => (props.$small ? '5px' : '10px')};
  white-space: nowrap;
  & > svg {
    /* display: block; */
  }
`;

export const Counter = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary.white};
  border-radius: 100px;
  padding: 2px 0.5em;
  font-size: 10px;
`;
