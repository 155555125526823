import React from 'react';
import * as Styled from './styled';

const wrapSearchedValue = (text: string = '', searchVal: string | null, className?: string) => {
  const query = searchVal || '';
  const loweredText = text.toLowerCase();
  const strIndex = loweredText.indexOf(query.toLowerCase());

  if (strIndex > -1) {
    const prefixText = text.substring(0, strIndex);
    const highlightText = text.substring(strIndex, strIndex + query.length);
    const suffixText = text.substring(strIndex + query.length, text.length + strIndex + query.length);
    return `${prefixText}<span class='${className}'>${highlightText}</span>${suffixText}`;
  }
  return text;
};

const Highlight: React.FC<{ searchVal?: string; children: string; className?: string }> = ({
  searchVal = null,
  children,
  className = 'highlight',
  ...props
}) => {
  const formattedSearchString = wrapSearchedValue(children, searchVal, className);
  return (
    <Styled.Highlight
      {...props}
      dangerouslySetInnerHTML={{ __html: formattedSearchString }} // eslint-disable-line react/no-danger
    />
  );
};

export default Highlight;
