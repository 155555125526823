'use client';
import * as Styled from '../styled';

import { IRepository, ISearchResponse } from '@/types';
import { components, ControlProps, GroupHeadingProps, OptionProps, SingleValueProps } from 'react-select';
import Highlight from '@/ui/Highlight';
import * as Icons from '@/ui/Icon';
import { RepositoryDataTypesWithSize } from '@/components/Repository/RepositoryDataTypes';

export interface ISearchResponseWithTermAndType extends ISearchResponse {
  searchTerm?: string;
  resultType?: string;
}

interface ResultIconProps {
  resultType: string | undefined;
}

const ResultIcon: React.FC<ResultIconProps> = ({ resultType }) => {
  switch (resultType) {
    case 'repository':
      return <Icons.Book />;
    case 'user':
      return <Icons.User />;
    case 'organization':
      return <Icons.Building />;
    default:
      return null;
  }
};

export const SingleValue = ({ data, ...otherProps }: SingleValueProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.SingleValue data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <ResultIcon resultType={data.resultType} />
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
          </span>
        </div>
        <div>
          {data.resultType === 'repository' && (
            <RepositoryDataTypesWithSize repository={data as IRepository} sizeInBytes={(data as IRepository).size} />
          )}
        </div>
      </Styled.OptionValue>
    </components.SingleValue>
  );
};

export const GroupHeading = (props: GroupHeadingProps<ISearchResponseWithTermAndType>) => (
  <div style={{ borderBottom: '1px solid black', color: 'black', paddingBottom: 5, fontWeight: 'bold' }}>
    <components.GroupHeading {...props} />
  </div>
);

export const SearchOption = ({ data, ...otherProps }: OptionProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.Option data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <ResultIcon resultType={data.resultType} />
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
            {/* {data.resultType === 'repository' && data.}</span>} */}
          </span>
        </div>
        <div>
          {data.resultType === 'repository' && (
            <RepositoryDataTypesWithSize repository={data as IRepository} sizeInBytes={(data as IRepository).size} />
          )}
        </div>
      </Styled.OptionValue>
    </components.Option>
  );
};

export const Control = ({
  children,
  prefix,
  ...props
}: ControlProps<ISearchResponseWithTermAndType> & { prefix?: React.ReactNode }) => {
  return (
    <components.Control {...props}>
      <span style={{ paddingLeft: '10px' }}>{prefix}</span>
      {children}
    </components.Control>
  );
};
