import * as Styled from './styled';

const Row: React.FC<{
  children?: React.ReactNode;
  gap?: number;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  theme?: any;
  style?: React.CSSProperties;
  className?: string;
}> = ({
  children,
  gap = 100,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  theme = {},
  className,
  style,
  ...props
}) => {
  return (
    <Styled.Row
      // className={`${styles.row} ${className}`}
      className={className}
      style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}
      {...props}
    >
      {children}
    </Styled.Row>
  );
};

export default Row;
