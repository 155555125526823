'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const RepositoryDataTypesChart = styled.div<{}>`
  display: flex;
  max-width: 300px;
  gap: 1px;
  /* border: 1px solid ${colors.secondary.lightGray3}; */
  border-radius: 5px;
  height: 10px;
  overflow: hidden;
`;

export const RepositoryDataTypeIndicators = styled.div<{}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const RepositoryDataTypeIndicator = styled.div<{}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  & > .bullet {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: ${colors.secondary.lightGray3};
  }
  & > .description {
    font-size: 12px;
    color: ${colors.primary.subtleText};
    .data-type {
      color: ${colors.primary.black};
      text-transform: capitalize;
    }
  }
`;
